window.tableRowClick = function(e, link) {
    var tagName = e.target.tagName;
    // console.log(tagName + " clicked")
    
    if (tagName == "DIV" || tagName == "TD" || tagName == "TR" || tagName == "P" || tagName == "IMG" || tagName == "SPAN") {
        // window.location = link;
        Turbo.visit(link)
    }

}

window.spanTagsClick = function(e, link) {
    var tagName = e.target.tagName;
    // console.log(tagName + " clicked")

    if (tagName == "SPAN") {
        // window.location = link;
        Turbo.visit(link)
    }

}