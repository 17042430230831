document.addEventListener("turbo:load", () => {
    //-----------------------------------------------------------------------------------------------------------------
    // Autocomplete search

    var sid = document.getElementById("searchid")

    function c(t) { console.log(t) }

    function get_selected_value_type(value) {
        for (let i = 0; i < tags.length; i++) {
            if (tags[i]["name"] == value) {
                return tags[i]["type"]
            }
        }
    }

    function change_search_url() {

        var searchInput = document.getElementById("myInput");

        if (searchInput.value == "" || searchInput.value == " ") {
            return
        }

        var form = document.getElementById("search_form")

        var route = "#"

        var s_value = searchInput.value

        var type = get_selected_value_type(s_value)

        // console.log("zzd")

        if (type === "tag") {
            route = "../" + s_value + "-jobs"
        } else if (type === "city") {
            route = "../web3-jobs-" + s_value
        } else if (type === "country") {
            route = "../web3-jobs-" + s_value
        } else if (type === "company") {
            route = "../web3-companies/" + s_value
        } else {
            route = null
        }

        // console.log("type: " + type)
        // console.log("type: " + s_value)


        if (route === null) {
            searchInput.disabled = true
            // searchInput.classList.remove("border-primary");
        } else {
            window.location.pathname = route
            // Turbo.visit(route)
        }

        // form.action = route
        // searchInput.disabled = true


        // c("type: " + type)
        // c("selected value: " + s_value)
        // c("route: " + route)


    }

    /* SA 2021-12-30; 927d; v8) */

    function create_search_div(el, a,b,val, inp) {
      /*create a DIV element for each matching element:*/
      b = document.createElement("DIV");
      /*make the matching letters bold:*/
      b.innerHTML = "<strong>" + el["name"].substr(0, val.length) + "</strong>";
      b.innerHTML += el["name"].substr(val.length);
      /*insert a input field that will hold the current array item's value:*/
      b.innerHTML += "<input type='hidden' value='" + el["name"] + "'>";
      /*execute a function when someone clicks on the item value (DIV element):*/
      b.addEventListener("click", function(e) {
          /*insert the value for the autocomplete text field:*/
          inp.value = this.getElementsByTagName("input")[0].value;
          console.log(inp.value)
          change_search_url()
          /*close the list of autocompleted values,
          (or any other open lists of autocompleted values:*/
          
      });
      a.appendChild(b);
    }


    function autocomplete(inp, arr) {


        var onlyFocused = false;
        
        front_tags = front_tags = [{name: "backend", type: "tag"}, {name: "community-manager", type: "tag"}, {name: "customer-support", type: "tag"}, {name: "design", type: "tag"}, {name: "front-end", type: "tag"}, {name: "full-stack", type: "tag"}, {name: "intern", type: "tag"}, {name: "marketing", type: "tag"}, {name: "mobile", type: "tag"}, {name: "non-tech", type: "tag"}, {name: "product-manager", type: "tag"}, {name: "sales",type: "tag"}]

        all_tags = arr

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("focus", function(e) {
            
            onlyFocused = true;

            var a, b, i, val = this.value;
            
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);

            arr = front_tags

            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/

                var el = arr[i]
                create_search_div(el, a,b,val, inp)
            }
        })

        inp.addEventListener("input", function(e) {

            var a, b, i, val = this.value;
            onlyFocused = false;
            
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) {
              
              onlyFocused = true;
              
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/

            if (onlyFocused) {
              arr = front_tags
            } else{
              arr = all_tags
            }

            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/

                var el = arr[i]

                if (onlyFocused) {
                  create_search_div(el, a,b,val, inp)
                  
                } else {
                  if (el["name"].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                     create_search_div(el, a,b,val, inp)
                  }
                }
            }

            if(document.getElementById("myInputautocomplete-list").childElementCount < 1) {
              inp.style.color = "red"
            } else {
              inp.style.color = "white"
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }

        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }

        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function(e) {
            closeAllLists(e.target);
        });
    }

    /*An array containing all the country names in the world:*/

    //todo
    // var tags = <%= Tag.autocomplete_tags %>

    /*initiate the autocomplete function on the "myInput" element, and pass along the tags array as possible autocomplete values:*/

    if (document.getElementById("myInput") != null) {
        tags = [
{
name: "account-manager",
type: "tag"
},
{
name: "accounting",
type: "tag"
},
{
name: "analyst",
type: "tag"
},
{
name: "android",
type: "tag"
},
{
name: "angular",
type: "tag"
},
{
name: "architecture",
type: "tag"
},
{
name: "aws",
type: "tag"
},
{
name: "backend",
type: "tag"
},
{
name: "big-data",
type: "tag"
},
{
name: "blockchain",
type: "tag"
},
{
name: "btc",
type: "tag"
},
{
name: "c",
type: "tag"
},
{
name: "c-plus-pls",
type: "tag"
},
{
name: "c-sharp",
type: "tag"
},
{
name: "clojure",
type: "tag"
},
{
name: "community-manager",
type: "tag"
},
{
name: "compliance",
type: "tag"
},
{
name: "consulting",
type: "tag"
},
{
name: "copywriting",
type: "tag"
},
{
name: "crypto",
type: "tag"
},
{
name: "cto",
type: "tag"
},
{
name: "customer-support",
type: "tag"
},
{
name: "dao",
type: "tag"
},
{
name: "dapp",
type: "tag"
},
{
name: "data-science",
type: "tag"
},
{
name: "support",
type: "tag"
},
{
name: "defi",
type: "tag"
},
{
name: "design",
type: "tag"
},
{
name: "dev",
type: "tag"
},
{
name: "devops",
type: "tag"
},
{
name: "dot-net",
type: "tag"
},
{
name: "economy-designer",
type: "tag"
},
{
name: "entry-level",
type: "tag"
},
{
name: "ethereum",
type: "tag"
},
{
name: "events-manager",
type: "tag"
},
{
name: "executive",
type: "tag"
},
{
name: "finance",
type: "tag"
},
{
name: "flutter",
type: "tag"
},
{
name: "fron-end",
type: "tag"
},
{
name: "full-stack",
type: "tag"
},
{
name: "full-time",
type: "tag"
},
{
name: "game-dev",
type: "tag"
},
{
name: "golang",
type: "tag"
},
{
name: "haskell",
type: "tag"
},
{
name: "hr",
type: "tag"
},
{
name: "illustrator",
type: "tag"
},
{
name: "intern",
type: "tag"
},
{
name: "ios",
type: "tag"
},
{
name: "java",
type: "tag"
},
{
name: "javascipt",
type: "tag"
},
{
name: "junior",
type: "tag"
},
{
name: "kyc",
type: "tag"
},
{
name: "lead",
type: "tag"
},
{
name: "legal",
type: "tag"
},
{
name: "machine-learning",
type: "tag"
},
{
name: "marketing",
type: "tag"
},
{
name: "metaverse",
type: "tag"
},
{
name: "mobile",
type: "tag"
},
{
name: "music",
type: "tag"
},
{
name: "nextjs",
type: "tag"
},
{
name: "nft",
type: "tag"
},
{
name: "node",
type: "tag"
},
{
name: "non-tech",
type: "tag"
},
{
name: "operations",
type: "tag"
},
{
name: "part-time",
type: "tag"
},
{
name: "php",
type: "tag"
},
{
name: "postgres",
type: "tag"
},
{
name: "product-manager",
type: "tag"
},
{
name: "project-manager",
type: "tag"
},
{
name: "python",
type: "tag"
},
{
name: "quality-assurance",
type: "tag"
},
{
name: "react",
type: "tag"
},
{
name: "react-native",
type: "tag"
},
{
name: "recruiter",
type: "tag"
},
{
name: "redis",
type: "tag"
},
{
name: "refi",
type: "tag"
},
{
name: "remote",
type: "tag"
},
{
name: "ruby",
type: "tag"
},
{
name: "rust",
type: "tag"
},
{
name: "sales",
type: "tag"
},
{
name: "scala",
type: "tag"
},
{
name: "scrum",
type: "tag"
},
{
name: "security",
type: "tag"
},
{
name: "senior",
type: "tag"
},
{
name: "seo",
type: "tag"
},
{
name: "smart-contract",
type: "tag"
},
{
name: "social-media",
type: "tag"
},
{
name: "solana",
type: "tag"
},
{
name: "solidity",
type: "tag"
},
{
name: "stats",
type: "tag"
},
{
name: "swift",
type: "tag"
},
{
name: "sys-admin",
type: "tag"
},
{
name: "tech-lead",
type: "tag"
},
{
name: "tezos",
type: "tag"
},
{
name: "trader",
type: "tag"
},
{
name: "translator",
type: "tag"
},
{
name: "typescript",
type: "tag"
},
{
name: "vfx",
type: "tag"
},
{
name: "video",
type: "tag"
},
{
name: "vue",
type: "tag"
},
{
name: "web3js",
type: "tag"
},
{
name: "work-from-home",
type: "tag"
},
{
name: "aba",
type: "city"
},
{
name: "abu-dhabi",
type: "city"
},
{
name: "abuja",
type: "city"
},
{
name: "aden",
type: "city"
},
{
name: "ahmedabad",
type: "city"
},
{
name: "amsterdam",
type: "city"
},
{
name: "ankara",
type: "city"
},
{
name: "antwerp",
type: "city"
},
{
name: "athens",
type: "city"
},
{
name: "atlanta",
type: "city"
},
{
name: "auckland",
type: "city"
},
{
name: "austin",
type: "city"
},
{
name: "bali",
type: "city"
},
{
name: "baltimore",
type: "city"
},
{
name: "bangalore",
type: "city"
},
{
name: "bangkok",
type: "city"
},
{
name: "barcelona",
type: "city"
},
{
name: "beijing",
type: "city"
},
{
name: "beirut",
type: "city"
},
{
name: "belgrade",
type: "city"
},
{
name: "bengaluru",
type: "city"
},
{
name: "berlin",
type: "city"
},
{
name: "bhopal",
type: "city"
},
{
name: "birmingham",
type: "city"
},
{
name: "bishkek",
type: "city"
},
{
name: "bogota",
type: "city"
},
{
name: "boston",
type: "city"
},
{
name: "brisbane",
type: "city"
},
{
name: "brussels",
type: "city"
},
{
name: "bucharest",
type: "city"
},
{
name: "budapest",
type: "city"
},
{
name: "buenos-aires",
type: "city"
},
{
name: "calgary",
type: "city"
},
{
name: "cape-town",
type: "city"
},
{
name: "caracas",
type: "city"
},
{
name: "chandigarh",
type: "city"
},
{
name: "charlotte",
type: "city"
},
{
name: "chennai",
type: "city"
},
{
name: "chicago",
type: "city"
},
{
name: "coimbatore",
type: "city"
},
{
name: "cologne",
type: "city"
},
{
name: "columbus",
type: "city"
},
{
name: "copenhagen",
type: "city"
},
{
name: "curitiba",
type: "city"
},
{
name: "dallas",
type: "city"
},
{
name: "delhi",
type: "city"
},
{
name: "denver",
type: "city"
},
{
name: "detroit",
type: "city"
},
{
name: "dhaka",
type: "city"
},
{
name: "dubai",
type: "city"
},
{
name: "dublin",
type: "city"
},
{
name: "edinburgh",
type: "city"
},
{
name: "essen",
type: "city"
},
{
name: "frankfurt",
type: "city"
},
{
name: "fukuoka",
type: "city"
},
{
name: "gebze",
type: "city"
},
{
name: "geneva",
type: "city"
},
{
name: "gothenburg",
type: "city"
},
{
name: "grenoble",
type: "city"
},
{
name: "guadalajara",
type: "city"
},
{
name: "hamburg",
type: "city"
},
{
name: "hamilton",
type: "city"
},
{
name: "helsinki",
type: "city"
},
{
name: "ho-chi-minh-city",
type: "city"
},
{
name: "hong-kong",
type: "city"
},
{
name: "houston",
type: "city"
},
{
name: "hyderabad",
type: "city"
},
{
name: "indianapolis",
type: "city"
},
{
name: "istanbul",
type: "city"
},
{
name: "izmir",
type: "city"
},
{
name: "jacksonville",
type: "city"
},
{
name: "jakarta",
type: "city"
},
{
name: "johannesburg",
type: "city"
},
{
name: "jos",
type: "city"
},
{
name: "kansas-city",
type: "city"
},
{
name: "kiev",
type: "city"
},
{
name: "kochi",
type: "city"
},
{
name: "kota",
type: "city"
},
{
name: "krakow",
type: "city"
},
{
name: "kuala-lumpur",
type: "city"
},
{
name: "la-laguna",
type: "city"
},
{
name: "lagos",
type: "city"
},
{
name: "las-vegas",
type: "city"
},
{
name: "lima",
type: "city"
},
{
name: "lisbon",
type: "city"
},
{
name: "london",
type: "city"
},
{
name: "los-angeles",
type: "city"
},
{
name: "louisville",
type: "city"
},
{
name: "lviv",
type: "city"
},
{
name: "lyon",
type: "city"
},
{
name: "madrid",
type: "city"
},
{
name: "malaga",
type: "city"
},
{
name: "manchester",
type: "city"
},
{
name: "manila",
type: "city"
},
{
name: "melbourne",
type: "city"
},
{
name: "memphis",
type: "city"
},
{
name: "menlo-park",
type: "city"
},
{
name: "mexico-city",
type: "city"
},
{
name: "miami",
type: "city"
},
{
name: "milan",
type: "city"
},
{
name: "minsk",
type: "city"
},
{
name: "montreal",
type: "city"
},
{
name: "moscow",
type: "city"
},
{
name: "mountain-view",
type: "city"
},
{
name: "mumbai",
type: "city"
},
{
name: "munich",
type: "city"
},
{
name: "nashville",
type: "city"
},
{
name: "new-york",
type: "city"
},
{
name: "newcastle-upon-tyne",
type: "city"
},
{
name: "novosibirsk",
type: "city"
},
{
name: "nyc",
type: "city"
},
{
name: "oklahoma-city",
type: "city"
},
{
name: "oslo",
type: "city"
},
{
name: "ottawa",
type: "city"
},
{
name: "palo-alto",
type: "city"
},
{
name: "panama-city",
type: "city"
},
{
name: "paris",
type: "city"
},
{
name: "philadelphia",
type: "city"
},
{
name: "phoenix",
type: "city"
},
{
name: "portland",
type: "city"
},
{
name: "porto",
type: "city"
},
{
name: "porto-alegre",
type: "city"
},
{
name: "prague",
type: "city"
},
{
name: "pune",
type: "city"
},
{
name: "quito",
type: "city"
},
{
name: "rabat",
type: "city"
},
{
name: "rasht",
type: "city"
},
{
name: "redmond",
type: "city"
},
{
name: "remote",
type: "city"
},
{
name: "reykjavik",
type: "city"
},
{
name: "riga",
type: "city"
},
{
name: "rio-de-janeiro",
type: "city"
},
{
name: "rome",
type: "city"
},
{
name: "sacramento",
type: "city"
},
{
name: "saint-petersburg",
type: "city"
},
{
name: "san-antonio",
type: "city"
},
{
name: "san-diego",
type: "city"
},
{
name: "san-francisco",
type: "city"
},
{
name: "san-jose",
type: "city"
},
{
name: "san-juan",
type: "city"
},
{
name: "santa-fe",
type: "city"
},
{
name: "santa-monica",
type: "city"
},
{
name: "santiago",
type: "city"
},
{
name: "sao-paulo",
type: "city"
},
{
name: "seattle",
type: "city"
},
{
name: "selangor",
type: "city"
},
{
name: "seoul",
type: "city"
},
{
name: "shanghai",
type: "city"
},
{
name: "singapore",
type: "city"
},
{
name: "sofia",
type: "city"
},
{
name: "stockholm",
type: "city"
},
{
name: "sydney",
type: "city"
},
{
name: "tainan",
type: "city"
},
{
name: "taipei",
type: "city"
},
{
name: "tallinn",
type: "city"
},
{
name: "tel-aviv",
type: "city"
},
{
name: "tokyo",
type: "city"
},
{
name: "toronto",
type: "city"
},
{
name: "toulouse",
type: "city"
},
{
name: "tucson",
type: "city"
},
{
name: "tunis",
type: "city"
},
{
name: "utrecht",
type: "city"
},
{
name: "van",
type: "city"
},
{
name: "vancouver",
type: "city"
},
{
name: "vienna",
type: "city"
},
{
name: "vilnius",
type: "city"
},
{
name: "warsaw",
type: "city"
},
{
name: "washington",
type: "city"
},
{
name: "winnipeg",
type: "city"
},
{
name: "zagreb",
type: "city"
},
{
name: "zug",
type: "city"
},
{
name: "zurich",
type: "city"
},
{
name: "argentina",
type: "city"
},
{
name: "armenia",
type: "city"
},
{
name: "australia",
type: "city"
},
{
name: "austria",
type: "city"
},
{
name: "azerbaijan",
type: "city"
},
{
name: "bangladesh",
type: "city"
},
{
name: "belarus",
type: "city"
},
{
name: "belgium",
type: "city"
},
{
name: "bolivia",
type: "city"
},
{
name: "brazil",
type: "city"
},
{
name: "bulgaria",
type: "city"
},
{
name: "cameroon",
type: "city"
},
{
name: "canada",
type: "city"
},
{
name: "chile",
type: "city"
},
{
name: "china",
type: "city"
},
{
name: "colombia",
type: "city"
},
{
name: "costa-rica",
type: "city"
},
{
name: "croatia",
type: "city"
},
{
name: "czech-republic",
type: "city"
},
{
name: "denmark",
type: "city"
},
{
name: "dominican-republic",
type: "city"
},
{
name: "ecuador",
type: "city"
},
{
name: "egypt",
type: "city"
},
{
name: "estonia",
type: "city"
},
{
name: "finland",
type: "city"
},
{
name: "france",
type: "city"
},
{
name: "georgia",
type: "city"
},
{
name: "germany",
type: "city"
},
{
name: "greece",
type: "city"
},
{
name: "guatemala",
type: "city"
},
{
name: "hong-kong",
type: "city"
},
{
name: "hungary",
type: "city"
},
{
name: "iceland",
type: "city"
},
{
name: "india",
type: "city"
},
{
name: "indonesia",
type: "city"
},
{
name: "iran",
type: "city"
},
{
name: "ireland",
type: "city"
},
{
name: "israel",
type: "city"
},
{
name: "italy",
type: "city"
},
{
name: "ivory-coast",
type: "city"
},
{
name: "japan",
type: "city"
},
{
name: "kazakhstan",
type: "city"
},
{
name: "kenya",
type: "city"
},
{
name: "kuwait",
type: "city"
},
{
name: "kyrgyzstan",
type: "city"
},
{
name: "latvia",
type: "city"
},
{
name: "lebanon",
type: "city"
},
{
name: "lithuania",
type: "city"
},
{
name: "malaysia",
type: "city"
},
{
name: "mexico",
type: "city"
},
{
name: "morocco",
type: "city"
},
{
name: "netherlands",
type: "city"
},
{
name: "new-zealand",
type: "city"
},
{
name: "nicaragua",
type: "city"
},
{
name: "nigeria",
type: "city"
},
{
name: "norway",
type: "city"
},
{
name: "pakistan",
type: "city"
},
{
name: "panama",
type: "city"
},
{
name: "peru",
type: "city"
},
{
name: "philippines",
type: "city"
},
{
name: "poland",
type: "city"
},
{
name: "portugal",
type: "city"
},
{
name: "puerto-rico",
type: "city"
},
{
name: "remote",
type: "city"
},
{
name: "romania",
type: "city"
},
{
name: "russia",
type: "city"
},
{
name: "serbia",
type: "city"
},
{
name: "singapore",
type: "city"
},
{
name: "south-africa",
type: "city"
},
{
name: "south-korea",
type: "city"
},
{
name: "spain",
type: "city"
},
{
name: "sweden",
type: "city"
},
{
name: "switzerland",
type: "city"
},
{
name: "taiwan",
type: "city"
},
{
name: "thailand",
type: "city"
},
{
name: "tunisia",
type: "city"
},
{
name: "turkey",
type: "city"
},
{
name: "uganda",
type: "city"
},
{
name: "ukraine",
type: "city"
},
{
name: "united-arab-emirates",
type: "city"
},
{
name: "united-kingdom",
type: "city"
},
{
name: "united-states",
type: "city"
},
{
name: "uruguay",
type: "city"
},
{
name: "uzbekistan",
type: "city"
},
{
name: "venezuela",
type: "city"
},
{
name: "vietnam",
type: "city"
},
{
name: "yemen",
type: "city"
},
{
name: "asia",
type: "city"
},
{
name: "europe",
type: "city"
},
{
name: "africa",
type: "city"
},
{
name: "oceania",
type: "city"
},
{
name: "north-america",
type: "city"
},
{
name: "south-america",
type: "city"
}
]

autocomplete(document.getElementById("myInput"), tags);

      // tags = []

      // function fetchJson(callback) {
      //     fetch("/search.json")
      //        .then(response => response.json())
      //        .then(json => callback(null, json))
      //        .catch(error => callback(error, null))
      // }

      // fetchJson((error, data) => {        
      //   tags = data
      //   autocomplete(document.getElementById("myInput"), tags);
      // });

    }

})