document.addEventListener("turbo:load", () => {

  if (document.getElementById("imgslider1") != null) {
    
    var ammount_of_globe_slider_images = 2;
    var i = 1;
    
    async function changeImagesInGlobeSlider() {
      while (true) {
          
        if(i == 1) {
          document.getElementById("imgslider1").style.display = "block";
          document.getElementById("imgslider2").style.display = "none";
        } else{
          document.getElementById("imgslider1").style.display = "none";
          document.getElementById("imgslider2").style.display = "block";
        }
      
        i++;

        if(i > ammount_of_globe_slider_images) {
          i = 1;
        }

        await new Promise(resolve => setTimeout(resolve, 3600));
        
       


      }
    }

    changeImagesInGlobeSlider();
}
});