document.addEventListener("turbo:load", () => {
    const salarySlider = document.getElementById('salarySlider')
    const salaryLabel = document.getElementById('salaryLabel')

    if (salarySlider != null) {
        salarySlider.oninput = function() {
            salaryLabel.innerHTML = "$" + parseInt(this.value).toLocaleString() + "+/year";
        }
    }

    const salarySlider_m = document.getElementById('salarySlider_m')
    const salaryLabel_m = document.getElementById('salaryLabel_m')

    if (salarySlider_m != null) {
        salarySlider_m.oninput = function() {
            salaryLabel_m.innerHTML = "$" + parseInt(this.value).toLocaleString() + "+/year";
        }
    }
})