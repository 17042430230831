document.addEventListener("turbo:load", () => {
 var isPlaying = !0;

function matrix() {
  // var ani = document.getElementById("carousel");
    if (isPlaying) {
        isPlaying = !1, document.getElementById("c").style.display = "block";
        // ani.style.animation = "paused";
        var e = document.getElementById("c"),
            t = e.getContext("2d");
        e.height = window.innerHeight, e.width = window.innerWidth;
        var l = "アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン@#$%^&*()*&^%+-/~{[|`]}";
        l = l.split("");
        for (var n = 10, i = e.width / n, d = [], o = 0; o < i; o++) d[o] = 100;
        setInterval(function() {
            t.fillStyle = "rgba(0, 0, 0, 0.01)", t.fillRect(0, 0, e.width, e.height), t.fillStyle = "#f4427d", t.font = n + "px arial";
            for (var i = 0; i < d.length; i++) {
                var o = l[Math.floor(Math.random() * l.length)];
                t.fillText(o, i * n, d[i] * n), d[i] * n > e.height && Math.random() > .975 && (d[i] = 0), d[i]++
            }
        }, 100)
    } else isPlaying = !0, document.getElementById("c").style.display = "none", document.getElementById("m").classList.remove("glow")
}


if (document.getElementById("c") != null) {
  matrix();
}

})